<template>
    <div>
        <!-- desktop -->
        <b-field label="Стиль" class="is-hidden-mobile">
          <b-checkbox-button
              v-model="selectedStroke"
              v-for="(s, index) in Events.stroke"
              :key="index"
              type="is-info"
              expanded
              :native-value="isValue(s[0])"
              :disabled="isDisabledStroke(s[1])"
          >
              <span>{{ s[1] }}</span>
          </b-checkbox-button>
        </b-field>

        <!-- desktop -->
        <b-field label="Дистанция" class="is-hidden-mobile">
          <b-checkbox-button
              v-model="selectedDistance"
              v-for="(s, index) in Events.distance"
              :key="index"
              type="is-info"
              expanded
              :native-value="s"
              :disabled="isDisabledDistance(s)"
          >
              <span>{{ s }}</span>
          </b-checkbox-button>
        </b-field>

        <b-field label="Бассейн" :message="messages.event" :type="inputColor(messages.event)" class="is-hidden-mobile">
        <b-checkbox-button
            v-model="selectedPool"
            v-for="(s, index) in Events.pool"
            :key="index"
            type="is-info"
            expanded
            :native-value="s"
            :disabled="isDisabledPool(s)"
        >
            <span>{{ s }}</span>
        </b-checkbox-button>
        </b-field>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import moment from 'moment';
// import _ from 'lodash';

export default {
  name: "SelectStrokeCheckbox",
  data: function () {
    return {
      // checkboxGroup: [],
      messages: "",
      // competion: "",
    };
  },
  components: {},
  // props: ['swim'],
  created: function () {
    if (this.isEdit) {
      let swim = this.getEditSwim();
      // Устанавливаем для страницы редактирования
      this.$store.dispatch("swim/editSwim", swim)
      this.$store.commit("swim/updateSelectedEvents",
        { stroke: swim.event_human.stroke, distance: swim.event_human.distance, pool: swim.event_human.pool })
      this.swim_id = swim.id;
      // this.competion = swim.competion ? swim.competion.id : 0;
    }
    else {
      // Object.assign(this.$data, this.$options.data())
      this.$store.commit("swim/updateSelectedEvents", { stroke: "", distance:"", pool: "" })
      // this.time = ""
    }
    this.$store.dispatch(
          "swim/filterEvents",
          this.$store.state.swim.selectedEvents
        );
    // this.$store.dispatch(
    //   "swim/getCompetion"
    // );
    // console.log(this.$store)
  },
  watch: {
  },
  methods: {
    ...mapActions("swim", ["filterEvents"]),
    ...mapGetters("swim", ["getEvents", "getEnabledEvents"]),

    getEditSwim() {
      // return this.$store.state.swim.editSwim;  // передача заплыва через vuex для модального окна
      return this.$route.params.swim;  // передача заплыва через router-link
    },
    isInfo: function() {
      return "is-info"
    },
    isValue: function(s) {
      return s;
    },
    isDisabledStroke: function (s) {
      return this.$store.state.swim.enabledEvents.stroke.flat().includes(s)
        ? false
        : true;
    },
    isDisabledDistance: function (s) {
      return this.$store.state.swim.enabledEvents.distance.includes(s)
        ? false
        : true;
    },
    isDisabledPool: function (s) {
      return this.$store.state.swim.enabledEvents.pool.includes(s)
        ? false
        : true;
    },
    inputColor: function (m) {
      if (m) {return 'is-danger'}
    },
  },
  computed: {
    Events: function () {
      // console.log('Events ', + this.$store.getters['swim/getEvents'].stroke[0])
      return this.$store.getters["swim/getEvents"];
    },
    isEdit: function() {
      return this.$route.name == "editswim" ? true : false
    },
    selectedStroke: {
      get() {
        if (this.$store.state.swim.selectedEvents.stroke != "") {  // Чтобы checkbox не передавал ["", val]
          return [].concat(this.$store.state.swim.selectedEvents.stroke);
        }
        else { return [] }
      },
      set(value) {
        if (value == "") {
          value = [""]
        }
        this.$store.commit("swim/updateSelectedEvents", { stroke: value[0] });
        this.$store.dispatch(
          "swim/filterEvents",
          this.$store.state.swim.selectedEvents
        );
        //this.debouncedGetGrade()
        // this.$emit('UpdateGrade')
      },
    },
    selectedDistance: {
      get() {
        if (this.$store.state.swim.selectedEvents.distance != "") {
          return [].concat(this.$store.state.swim.selectedEvents.distance);
        }
        else { return [] }
      },
      set(value) {
        if (value == "") {
          value = [""]
        }
        this.$store.commit("swim/updateSelectedEvents", { distance: value[0] });
        this.$store.dispatch(
          "swim/filterEvents",
          this.$store.state.swim.selectedEvents
        );
        // this.debouncedGetGrade()
        // this.$emit('UpdateGrade')
      },
    },
    selectedPool: {
      get() {
        if (this.$store.state.swim.selectedEvents.pool != "") {
          return [].concat(this.$store.state.swim.selectedEvents.pool);
        }
        else { return [] }
      },
      set(value) {
        if (value == "") {
          value = [""]
        }
        this.$store.commit("swim/updateSelectedEvents", { pool: value[0] });
        this.$store.dispatch(
          "swim/filterEvents",
          this.$store.state.swim.selectedEvents
        );
        // this.debouncedGetGrade()
        // this.$emit('UpdateGrade')
      },
    },
  }
};
</script>

<style scoped>
/* .select select option:disabled {
    color: #c3c3c3;
} */
</style>