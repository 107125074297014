<template>
  <div>
      <Header/>
      <LoginBase/>
  </div>
</template>


<script>
    import Header from '../components/Header.vue';
    import LoginBase from '../components/LoginBase.vue';

    export default {
        name: 'Login',
        components: {
            Header,
            LoginBase,
        }
    }
</script>

<style scoped>
</style>