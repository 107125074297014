<template>
    <section class="hero is-medium is-link">
          <div class="hero-body">
            <p class="title">
              Время плавать!
            </p>
            <p class="subtitle">
              Сохрани свои заплывы.
            </p>
          </div>
        </section>
</template>

<script>

export default {
  name: 'Helo',
}
</script>
