<template>
  <div class="container">
    <section>
        <b-dropdown
            v-model="selectedOptions"
            multiple
            :close-on-click="false"
            :append-to-body="false"
            :mobile-modal="false"
            aria-role="list"
            position="is-bottom-left"
            >
            <template #trigger>
                <div class="is-hidden-mobile">
                    <b-button
                        type="is-light mr-2"
                        icon-right="filter">{{ selectedOptions.length }}
                    </b-button>
                </div>
                <div class="is-hidden-tablet">
                    <b-button class="navbar-item"
                        type="mr-2 is-ghost"
                        icon-right="">Фильтр заплывов ({{ selectedOptions.length }})
                    </b-button>
                </div>
            </template>

            <template v-for="(s, index) in events.stroke" >
                <b-dropdown-item :value="'stroke:' + s[0]" aria-role="listitem" :key="index">
                    <span>{{ s[1]}}</span>
                </b-dropdown-item>
                </template>

            <b-dropdown-item separator/>

            <template v-for="(s, index) in events.distance" >
                <b-dropdown-item :value="'distance:' + s" aria-role="listitem" :key="index+10">
                    <span>{{ s }}</span>
                </b-dropdown-item>
            </template>

            <b-dropdown-item separator/>

            <template v-for="(s, index) in events.pool" >
                <b-dropdown-item :value="'pool:' + s" aria-role="listitem" :key="index+20">
                    <span>{{ s }}</span>
                </b-dropdown-item>
            </template>
         </b-dropdown>
     </section>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import _ from 'lodash';

export default {
  name: "FilterSwim",
  data: function () {
    return {
        selectedOptions: [],
    };
  },
  components: {},
  created: function(){
      this.debouncedGetSwims = _.debounce(this.getSwims, 500)
  },
  watch: {
      params: function(){
         // this.$store.dispatch("swim/getAllSwims", { payload: this.params } )
         this.debouncedGetSwims()
      },
  },
  methods: {
    ...mapActions('swim', ['getAllSwims']),
    getSwims: function() {
        this.$store.dispatch("swim/getAllSwims", { payload: this.params } )
    },
  },
  computed: {
      ...mapState({
          events: state => state.swim.events
      }),
      params: function(){
          let data = {'stroke': [], 'distance': [], 'pool': []}

          this.selectedOptions.forEach(element => {
              let p = element.split(":")
              data[p[0]].push(p[1])
          });

          return data
      },
  },
};
</script>
