import { jwtDecrypt, tokenAlive } from "../../shared/jwtHelper";
import jwtInterceptor from '../../shared/jwtInterceptor'
import axios from "axios";

const state = () => ({
    authData: {
        token: "",
        refresh: "",
        exp: "",
        user: "",
    },
    loginStatus: "",
});

const getters = {
    getLoginStatus(state) {
        return state.loginStatus;
    },
    getAuthData(state) {
        return state.authData;
    },
    isTokenActive(state) {
        if (!state.authData.exp) {
            return false;
        }
        return tokenAlive(state.authData.exp);
    },
};

const actions = {
    async login({ commit }, payload) {
        const response = await axios
            .post("/auth/login", payload)
            .catch((err) => {
                console.log(err);
            });
        if (response && response.data) {
            // console.log(response.data)
            commit("saveTokenData", response.data.data);
            commit("setLoginStatu", "success");
        } else {
            commit("setLoginStatu", "failed");
        }
    },
    async loginPromise({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/auth/login", payload)
                .then((response) => {
                    resolve(response);
                    commit("saveTokenData", response.data.data);
                    commit("setLoginStatu", "success");
                })
                .catch((error) => {
                    reject(error.response);
                    commit("setLoginStatu", "failed");
                });
        })
    },
    async signup({ commit }, payload) {
        await axios
            .post("/auth/signup", payload)
            .then(function() {
                commit("setLoginStatu", "signup");
            })
            .catch((err) => {
                console.log(err);
            });
    },
    async signUpPromise(ctx, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/auth/signup", payload)
                .then(function(response) {
                    resolve(response);
                    // commit("setLoginStatu", "signup");
                })
                .catch((error) => {
                    // console.log(error);
                    reject(error.response.data)
                })
        })
    },
    async logout({ commit }) {
        await commit('clearTokenData')
    },
    async validate({ commit }) {
        await jwtInterceptor.get('/auth/login')
            .then((response) => {
                commit("setLoginStatu", "success")
                console.log((response.data))
            })
            .catch((err) => {
                console.log(err)
                commit('clearTokenData');
            })
    },

    //.then(
    // 
    // )
    // .catch((err) => {
    //     console.log(err);
    // }),
    //

    async initialUser({ commit }, payload) {
        console.log('Initial User')
        const token = payload.token
        const refresh = payload.refresh

        await commit('saveTokenData', { token, refresh });
        // await dispatch('validate')
    },
};

const mutations = {
    saveTokenData(state, data) {
        localStorage.setItem("token", JSON.stringify(data.token));
        localStorage.setItem("refresh", JSON.stringify(data.refresh));

        const jwtDecodedValue = jwtDecrypt(data.token);
        console.log(jwtDecodedValue)
        const newTokenData = {
            token: data.token,
            refresh: data.refresh,
            exp: jwtDecodedValue.exp,
            user: jwtDecodedValue.sub,
        };

        state.authData = newTokenData;
    },
    clearTokenData(state) {
        localStorage.clear()

        const clearAuthData = {
            token: "",
            refresh: "",
            exp: "",
            user: "",
        }

        state.authData = clearAuthData;
    },
    setLoginStatu(state, value) {
        state.loginStatus = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};