<template>
  <div>
    <Header/>
  </div>
</template>


<script>
    import Header from '../components/Header';

    export default {
        name: 'Home',
        metaInfo: {
            title: 'Время плавать',
            meta: [
                { vmid: 'description', property: 'description',
                content: 'Сохрани результаты своих заплывов. Поиск и сортировка по результатам. Автоматическое вычисление очков FINA и разрада по нормативам ВФП. Калькулятор очков FINA 2021 и разрядов ВФП' },
                { vmid: 'og:title', property: 'og:title',
                content: 'Сохрани результаты своих заплывов. Поиск и сортировка по результатам. Автоматическое вычисление очков FINA и разрада по нормативам ВФП. Калькулятор очков FINA 2021 и разрядов ВФП' },
                { vmid: 'og:description', property: 'og:description',
                content: 'Сохрани результаты своих заплывов. Поиск и сортировка по результатам. Автоматическое вычисление очков FINA и разрада по нормативам ВФП. Калькулятор очков FINA 2021 и разрядов ВФП' },
            ],
        },
        components: {
            Header,
        }
    }
</script>

<style scoped>
</style>