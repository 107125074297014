<template>
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5-tablet is-4-desktop is-3-widescreen">
            <form class="box" @submit.prevent="submit">
              <b-field label="Эл. почта" :message="messages.username" :type="inputColor(messages.username)">
              <!-- <b-field label="Логин" :message="msg" v-bind:type="{ 'is-danger': hasError }"> -->
                <b-input type="input" name="username" v-model="username"></b-input>
              </b-field>
              <b-field label="Пароль" :message="messages.password" :type="inputColor(messages.password)">
                <b-input type="password" name="password" v-model="password"
                  value=""
                  password-reveal>
                </b-input>
              </b-field>
              <b-button native-type="submit" type="is-success">Вход</b-button>
            </form>
          </div>
        </div>
      </div>
</template>

<script>
  import {mapActions} from 'vuex';

  export default {
    name: 'LoginBase',
    data: function () {
      return {
        username: '',
        password: '',
        messages: {},
      }
    },
    components: {
    },
    methods:  {
      ...mapActions("auth", ['loginPromise']),
      submit: function () {
        // try {
          let th = this;
          this.loginPromise({username: this.username.toLowerCase().trim(), password: this.password}).then(() => {
            // console.log(JSON.parse(localStorage.getItem('token', '')));
          this.$router.push('/swims')
      })
      .catch((error) => {
        // console.log(error)
        th.messages = error.data.message
        if (error.status == 401) {
          th.messages = {submit: 'Неверный логин или пароль'}
          this.$buefy.toast.open({
              duration: 5000,
              message: `Неверный логин или пароль!`,
              position: 'is-bottom',
              type: 'is-danger'
          })
        }
        });
        // }
        // catch {
        //   console.log('Error')
        // }
      },
      inputColor: function (m) {
          if (m) {return 'is-danger'}
      },
    },
    // проксирует `this.doneCount` в `store.getters.doneTodosCount`
    computed: {}

     // async submit() {
      //   const response = await apiClient.post('/auth/login', {username: this.username, password: this.password});
      //   console.log(response)
      //   // try {
      //   //   const response = await this.$auth.login({
      //   //     // username: 'aaa',
      //   //     // password: '123'
      //   //     username: this.username,
      //   //     password: this.password
      //   //   })

      //   //   console.log(response)
      //   // }
      //   // catch {
      //   //   // this.errorMessages = response;
      //   // }
      // },
  }
</script>
