<template>
  <div>
      <Header/>
      <div class="columns">
          <div class="column"><Swim/></div>
      </div>
  </div>
</template>


<script>
    import Header from '../components/Header';
    import Swim from '../components/Swim';

    export default {
        name: 'Swims',
        components: {
            Header,
            Swim
        }
    }
</script>

<style scoped>
</style>