<template>
  <div class="container">
    <div class="columns is-centered">
      <!-- <div class="column is-5-tablet is-6-desktop is-5-widescreen"> -->
      <div class="column">
        <form class="box" @submit.prevent="submit">
          <div class="columns">
            <div class="column is-5">
              <SelectStroke /><SelectStrokeMobile />
            </div>
            <div class="column is-3">
              <TimeInput />

              <!-- <b-field label="Разряд">
                <b-input icon="medal" disabled v-model="grade"></b-input>
              </b-field> -->
              <b-field label="Разряд">
                <b-input icon="medal" disabled v-model="gradeExt"> </b-input>
              </b-field>
              <b-field label="Очки FINA">
                <b-input icon="star-half-alt" disabled v-model="pointsExt"></b-input>
              </b-field>
            </div>

            <div class="column is-4">
              <b-field label="Дата заплыва" :message="messages.date" :type="inputColor(messages.date)">
                <b-datepicker v-model="date"
                  placeholder="Нажми для выбора..."
                  icon="calendar-alt"
                  trap-focus
                  editable
                  locale="ru"
                  first-day-of-week=1
                >
                </b-datepicker>
              </b-field>
              <b-field label="Соревнования">
                <b-select v-model="competion" expanded placeholder="Выбери соревнования" icon="trophy">
                  <option
                    v-for="comp in Competions"
                    :value="comp.id"
                    :key="comp.id">
                    {{ comp.name }} <template v-if="comp.step"> / {{ comp.step }}</template>
                  </option>
                </b-select>
              </b-field>

              <b-field label="Комментарий">
                <b-input v-model="comment" icon="comment"></b-input>
              </b-field>

            </div>
          </div>

          <div v-if="isEdit">
            <b-button native-type="submit" type="is-success">Сохранить</b-button>
          </div>
          <div v-else>
            <b-button native-type="submit" type="is-success" v-if="! isEdit">Добавить</b-button>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SelectStroke from '../components/SelectStrokeCheckbox';
import SelectStrokeMobile from '../components/SelectStrokeMobile';
import TimeInput from '../components/TimeInput';

import { mapActions, mapGetters } from "vuex";
import moment from 'moment';
import _ from 'lodash';

export default {
  name: "AddSwimBase",
  data: function () {
    return {
      // checkboxGroup: [],
      time: "",
      date: new Date(),
      comment: "",
      grade: "",
      competion: "",
      messages: {time: ''},
      swim_id: "",
      // isEdit: false,
    };
  },
  components: {
      SelectStroke,
      SelectStrokeMobile,
      TimeInput,
  },
  // props: ['swim'],
  created: function () {
    console.log('created')
    if (this.isEdit) {
      let swim = this.getEditSwim();
      // Устанавливаем для страницы редактирования
      this.$store.dispatch("swim/editSwim", swim)
      this.$store.commit("swim/updateSelectedEvents",
        { stroke: swim.event_human.stroke, distance: swim.event_human.distance, pool: swim.event_human.pool })
      this.$store.commit("swim/updateTime", swim.time_str.split(":").slice(1).join(":"));
      this.swim_id = swim.id;
      this.date = new Date(swim.date);
      this.comment = swim.comment;
      this.competion = swim.competion ? swim.competion.id : 0;
      this.time = swim.time_str.split(":").slice(1).join(":")
    }
    else {
      // Object.assign(this.$data, this.$options.data())
      this.$store.commit("swim/updateSelectedEvents", { stroke: "", distance:"", pool: "" })
      this.$store.commit("swim/updateTime", "");
      // this.time = ""
    }
    this.$store.commit("swim/updateGrade", "")
    this.$store.dispatch(
          "swim/filterEvents",
          this.$store.state.swim.selectedEvents
        );
    this.$store.dispatch(
      "swim/getCompetion"
    );
    this.debouncedGetGrade = _.debounce(this.getGrade, 1000)
    // this.debouncedGetPoints = _.debounce(this.getPoints, 1000)
    // console.log(this.$store)
  },
  watch: {
    '$store.state.swim.time': function () {
      this.debouncedGetGrade()
      // this.debouncedGetPoints()
    },
    date: function() {
      this.debouncedGetGrade()
      // this.debouncedGetPoints()
    },
    // $route(to, from) {
    //   // обрабатываем изменение параметров маршрута...
    //   console.log('Router watch', to.name, from.name)
    //   // this.$destroy();
    //   if (to.name == 'addswim') {
    //     this.$store.dispatch("swim/editSwim", {})
    //     this.$store.commit("swim/updateSelectedEvents",
    //       { stroke: "", distance: "", pool: "" })
    //     this.swim_id = "";
    //     this.date = new Date();
    //     this.comment = "";
    //     this.competion = "";
    //     this.time = ""

    //     // this.time = ""
    //     // console.log('LINK TO ADDSWIM')
    //     // this.$store.dispatch("swim/editSwim", {})
    //     // // this.$destroy();
    //     // this.$store.commit("swim/updateSelectedEvents", { stroke: "", distance:"", pool: "" });
    //     // // Object.assign(this.$data, this.$options.data())
    //     // Object.assign(this.$data, this.$options.data.apply(this))
    //   }
    //   else {
    //     console.log('LINK TO ', to.name)
    //   }
    // }
  },
  methods: {
    ...mapActions("swim", ["filterEvents", "addSwim", "addSwimPromise", "editSwimPromise", "filterGrade", "filterPoints"]),
    ...mapGetters("swim", ["getEvents", "getEnabledEvents"]),
    submit: function() {
      let th = this;
      const payload = {
        event: this.$store.state.swim.enabledEvents.event.length == 1 ? this.$store.state.swim.enabledEvents.event[0] : 0,
        // time: this.time,
        time: this.realTime,
        date: moment(this.date).format("YYYY-MM-DD"),
        competion: this.competion,
        comment: this.comment
        }
      // this.addSwim(payload)
      if (th.isEdit) {
        payload.id = th.swim_id;
        this.editSwimPromise(payload)
        .then((res) => {
          console.log(res)
          this.$store.dispatch("swim/getAllSwims");
          // this.$parent.close()
          this.$router.push('swims')
          })
        .catch((error) => {
          console.log(error)
          th.messages = error.message
          })
      }
      else {
        this.addSwimPromise(payload)
        .then((res) => {
          console.log(res)
          this.$router.push('swims')
          })
        .catch((error) => {
          console.log(error)
          th.messages = error.message
          })
      }
    },
    getEditSwim() {
      // return this.$store.state.swim.editSwim;  // передача заплыва через vuex для модального окна
      return this.$route.params.swim;  // передача заплыва через router-link
    },
    getGrade: function() {
      const event = this.$store.state.swim.enabledEvents.event
      let payload = {
        event: event.length == 1 ? event[0] : "",
        // swimtime: "00:" + this.time,
        swimtime: this.realTime,
        swimdate: this.date,
        // sex: 1
        }

      if (payload.event > 0 && payload.swimtime.length >= 8 && payload.swimdate) {
        console.log(payload)
        this.$store.dispatch("swim/filterGrade", payload);
        this.$store.dispatch("swim/filterPoints", payload);
        // this.grade = this.$store.state.swim.grade.label
        // console.log(this.$store.state.swim.grade)
      }
      else {
        console.log(payload)
      }
    },
    isInfo: function() {
      return "is-info"
    },
    isValue: function(s) {
      return s;
    },
    isDisabledStroke: function (s) {
      return this.$store.state.swim.enabledEvents.stroke.flat().includes(s)
        ? false
        : true;
    },
    isDisabledDistance: function (s) {
      return this.$store.state.swim.enabledEvents.distance.includes(s)
        ? false
        : true;
    },
    isDisabledPool: function (s) {
      return this.$store.state.swim.enabledEvents.pool.includes(s)
        ? false
        : true;
    },
    inputColor: function (m) {
      if (m) {return 'is-danger'}
    },
    // getSelectedStroke: function() {
    //   return [].concat(this.$store.state.swim.selectedEvents.stroke);
    // },
  },
  computed: {
    Events: function () {
      // console.log('Events ', + this.$store.getters['swim/getEvents'].stroke[0])
      return this.$store.getters["swim/getEvents"];
    },
    Competions: function () {
      return [{"id": "", "name": ""}].concat(this.$store.state.swim.competion);
    },
    realTime: function() {  // В поле время в формате ММ:СС.мс и поэтому добавляем префик 00: (часы)
      return "00:" + this.$store.state.swim.time
    },
    isEdit: function() {
      return this.$route.name == "editswim" ? true : false
    },
    selectedStroke: {
      get() {
        return [].concat(this.$store.state.swim.selectedEvents.stroke);
      },
      // get: function() {
      //   return this.getSelectedStroke();
      // },
      set(value) {
        this.$store.commit("swim/updateSelectedEvents", { stroke: value[1] });
        this.$store.dispatch(
          "swim/filterEvents",
          this.$store.state.swim.selectedEvents
        );
        this.debouncedGetGrade()
      },
    },
    selectedDistance: {
      get() {
        return [].concat(this.$store.state.swim.selectedEvents.distance);
      },
      set(value) {
        // Почему-то checkbox передаёт ["", val]
        this.$store.commit("swim/updateSelectedEvents", { distance: value[1] });
        this.$store.dispatch(
          "swim/filterEvents",
          this.$store.state.swim.selectedEvents
        );
        this.debouncedGetGrade()
      },
    },
    selectedPool: {
      get() {
        return [].concat(this.$store.state.swim.selectedEvents.pool);
      },
      set(value) {
        this.$store.commit("swim/updateSelectedEvents", { pool: value[1] });
        this.$store.dispatch(
          "swim/filterEvents",
          this.$store.state.swim.selectedEvents
        );
        this.debouncedGetGrade()
      },
    },
    gradeExt: {
      get() {
        const grade = this.$store.state.swim.grade
        if (grade.label) {
          return grade.label + " / " + moment(grade.time_str, "hh:mm:ss.SSSSSS").format('mm:ss.SS')
        }
        else {
          return ""
        }
      },
      set() {
        // this.debouncedGetGrade()
      }
    },
    pointsExt: {
      get() {
        return this.$store.state.swim.points
      },
      set() {
        // this.debouncedGetPoints()
      }
    },
  },
};
</script>
