<template>
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5-tablet is-4-desktop is-3-widescreen">
            <form class="box" @submit.prevent="submit">
              <b-field label="Имя" :message="messages.name" :type="inputColor(messages.name)">
                <b-input type="input" name="name" v-model="name"></b-input>
              </b-field>
              <b-field label="Пол" :message="messages.sex" :type="inputColor(messages.sex)">
                  <b-radio v-model="sex"
                    name="sex"
                    native-value="1">
                    <b-icon icon="male" size="is-medium"></b-icon>
                </b-radio>
                <b-radio v-model="sex"
                    name="sex"
                    native-value="2">
                    <b-icon icon="female" size="is-medium"></b-icon>
                </b-radio>
              </b-field>
              <b-field label="Эл. почта" :message="messages.username" :type="inputColor(messages.username)">
                <b-input type="input" name="username" v-model="username"></b-input>
              </b-field>
              <b-field label="Пароль" :message="messages.password" :type="inputColor(messages.password)">
                <b-input type="password" name="password" v-model="password"
                  value=""
                  password-reveal>
                </b-input>
              </b-field>
              <b-button native-type="submit" type="is-success">Регистрация</b-button>
            </form>
          </div>
        </div>
      </div>
</template>

<script>
  import {mapActions} from 'vuex';

  export default {
    name: 'SignUpBase',
    data: function () {
      return {
        username: '',
        password: '',
        name: '',
        sex: '',
        messages: {},
      }
    },
    components: {
    },
    methods:  {
        ...mapActions("auth", ['signUpPromise']),
        ...mapActions("auth", ['login']),
        submit: function () {
            let th = this;
            this.signUpPromise({username: this.username.toLowerCase().trim(), password: this.password, name: this.name, sex: this.sex})
            .then(() => {
                th.login({username: th.username, password: th.password}).then(() => {
                    th.$router.push('/swims')
                })
            })
            .catch((error) => {
              console.log(error.response)
              th.messages = error.message
            });
        },
        inputColor: function (m) {
          if (m) {return 'is-danger'}
        },
    },
  }
</script>
