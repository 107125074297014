import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import Cleave from 'cleave.js'
// import moment from "moment";
import VueMask from 'v-mask'
import VueYandexMetrika from 'vue-yandex-metrika'
import VueMeta from 'vue-meta'

import Buefy from "buefy";  // UI components based on Bulma; https://github.com/buefy/buefy
import "buefy/dist/buefy.css";
// import "./assets/app.scss";
import "@mdi/font/css/materialdesignicons.min.css";

// icons
import { library } from '@fortawesome/fontawesome-svg-core';
// internal icons
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);
Vue.component('vue-fontawesome', FontAwesomeIcon);


// router.beforeEach((to, from, next) => {
//     // if any of the routes in ./router.js has a meta named 'requiresAuth: true'
//     // then check if the user is logged in before routing to this path:
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         if (!store.getters.loggedIn) {
//             next({ name: 'login' })
//         } else {
//             next()
//         }
//     } else if (to.matched.some(record => record.meta.requiresLogged)) {
//         // else if any of the routes in ./router.js has a meta named 'requiresLogged: true'
//         // then check if the user is logged in; if true continue to home page else continue routing to the destination path
//         // this comes to play if the user is logged in and tries to access the login/register page
//         if (store.getters.loggedIn) {
//             next({ name: 'home' })
//         } else {
//             next()
//         }
//     } else {
//         next()
//     }
// })

Vue.use(Buefy, {
    defaultIconComponent: 'vue-fontawesome',
    defaultIconPack: 'fas',
    // defaultDateFormatter: date => {
    //     return moment(date).format("YYYY-MM-DD");
    // },
    // defaultDateParser: date => {
    //     return moment(date, "YYYY-MM-DD").toDate();
    // }
});

Vue.use(VueMask);

Vue.use(VueYandexMetrika, {
    id: 86506319,
    router: router,
    env: process.env.NODE_ENV
})

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
})

Vue.directive('cleave', {
    bind(el, binding) {
        const input = el.querySelector('input')
            // @ts-ignore
        input._vCleave = new Cleave(input, binding.value)
    },
    update: (el) => {
        const input = el.querySelector('input')
        const event = new Event('input', { bubbles: true });
        setTimeout(function() {
            input.value = input._vCleave.properties.result
            input.dispatchEvent(event)
        }, 100);
    },
    unbind(el) {
        const input = el.querySelector('input')
            // @ts-ignore
        input._vCleave.destroy()
    }
})

Vue.config.productionTip = false

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')
