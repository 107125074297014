<template>
<div>
    <b-field label="Результат" :message="messages.time" :type="inputColor(messages.time)">
            <b-input v-model="time"
                icon="clock"
                placeholder="ММ:СС.мм"
                v-mask="'##:##.##'"
                type="tel"
            >
            </b-input>
    </b-field>
    </div>
</template>

<script>
// import moment from 'moment';
// import { VueMaskDirective } from 'v-mask'

export default {
  // directives: { VueMaskDirective },
  name: "TimeInput",
  data: function () {
    return {
        messages: {time: ""},
        // xtime: "",
    };
  },
    methods: {
        inputColor: function (m) {
            if (m) {return 'is-danger'}
        },
    },
    // watch: {
    //     xtime: function() {
    //         this.$store.commit("swim/updateTime", this.xtime);
    //     },
    // },
    computed: {
        time: {
            get: function() {
                // console.log("GET: " + this.$store.state.swim.time.split(":").slice(1).join(":"))
                // return "" + this.$store.state.swim.time.split(":").slice(1).join(":");
                return "" + this.$store.state.swim.time;
            },
            set: function(value) {
                console.log("SET: " + value)
                // let tm = "00:" + value
                this.$store.commit("swim/updateTime", value);
            },
        },
    },
}
</script>

<style scoped>
.field {
    margin-bottom: .75rem
}
</style>
