import jwtInterceptor from '../../shared/jwtInterceptor';
// import axios from "axios";
import Qs from 'qs';

const state = () => ({
    swims: [],
    events: {
        stroke: [
            ['1', 'FREE'],
            ['2', 'BACK'],
            ['3', 'BREAST'],
            ['4', 'FLY'],
            ['5', 'MEDLEY']
        ],
        distance: ['50', '100', '200', '400', '800', '1500'],
        pool: ['25', '50']
    },
    selectedEvents: { stroke: "", distance: "", pool: "" },
    editSwim: {},
    enabledEvents: {
        stroke: [
            ['1', 'FREE'],
            ['2', 'BACK'],
            ['3', 'BREAST'],
            ['4', 'FLY'],
            ['5', 'MEDLEY']
        ],
        distance: ['50', '100', '200', '400', '800', '1500'],
        pool: ['25', '50'],
        event: []
    },
    sex: "",
    time: "",
    grade: "",
    points: "",
    competion: [],
});

const getters = {
    getSwims(state) {
        return state.swims;
    },
    getEvents(state) {
        return state.events
    },
    // getEnabledEvents(state) {
    //     return state.events
    // },
};

const actions = {
    async getAllSwims({ commit }, params) {
        await jwtInterceptor.get('/api/swim', {
                params,
                paramsSerializer: function(params) { // Данные приходят {payload: {stroke: [], distance: [], pool: []}}
                    return Qs.stringify(params.payload, { arrayFormat: 'repeat' })
                },
            })
            .then(function(response) {
                commit('updateSwims', response.data.data);
            });
        // if (response && response.data) {
        //     commit('updateSwims', response.data.data);
        // }
    },
    async addSwim(ctx, payload) {
        // console.log(payload)
        await jwtInterceptor.post('/api/swim', payload)
            .then(function(response) {
                console.log(response.data)
                    // dispatch('getAllSwims')
            })
            .catch(function(error) {
                console.log(error)
            })
    },
    async addSwimPromise(ctx, payload) { // Новый action, чтобы пробросить ошибки в компонент
        // console.log(payload)
        return new Promise((resolve, reject) => {
            jwtInterceptor.post('/api/swim', payload)
                .then(function(response) {
                    resolve(response)
                })
                .catch(function(error) {
                    reject(error.response.data)
                })
        })
    },
    async editSwimPromise(ctx, payload) { // Новый action, чтобы пробросить ошибки в компонент
        // console.log(payload)
        return new Promise((resolve, reject) => {
            jwtInterceptor.put('/api/swim/' + payload.id, payload)
                .then(function(response) {
                    resolve(response)
                })
                .catch(function(error) {
                    reject(error.response.data)
                })
        })
    },
    async delSwimPromise(ctx, payload) { // Новый action, чтобы пробросить ошибки в компонент
        // console.log(payload)
        return new Promise((resolve, reject) => {
            jwtInterceptor.delete('/api/swim/' + payload.id)
                .then(function(response) {
                    resolve(response)
                })
                .catch(function(error) {
                    reject(error.response.data)
                })
        })
    },
    async filterEvents({ commit }, params) {
        await jwtInterceptor.get('/api/event', { params })
            .then(function(response) {
                // console.log(response);
                commit('updateEnabledEvents', response.data.data);
            })
            .catch(function(error) {
                console.log(error);
            })
    },
    async filterGrade({ commit }, params) {
        await jwtInterceptor.get('/api/grade', { params }).
        then((response) => {
                // console.log(response)
                commit('updateGrade', response.data.data)
            })
            .catch((error) => {
                console.log(error)
                commit('updateGrade', '')
            })
    },
    async filterPoints({ commit }, params) {
        await jwtInterceptor.get('/api/points', { params }).
        then((response) => {
                // console.log(response)
                commit('updatePoints', response.data.data)
            })
            .catch((error) => {
                console.log(error)
                commit('updatePoints', '')
            })
    },
    async getCompetion({ commit }) {
        var response = await jwtInterceptor.get('/api/competion');
        if (response && response.data) {
            commit('updateCompetion', response.data.data);
        }
    },
    async editSwim({ commit }, payload) {
        commit('editSwim', payload);
    },
};

const mutations = {
    updateSwims(state, payload) {
        state.swims = payload
    },
    updateEnabledEvents(state, payload) {
        // console.log(payload)
        state.enabledEvents = payload
    },
    updateSelectedEvents(state, payload) {
        console.log('Mutation: updateSelectedEvents' + JSON.stringify(payload));
        Object.assign(state.selectedEvents, payload);
    },
    updateTime(state, payload) {
        // console.log(payload);
        state.time = payload;
    },
    updateGrade(state, payload) {
        // console.log(payload)
        state.grade = payload
    },
    updatePoints(state, payload) {
        // console.log(payload)
        state.points = payload.points
    },
    updateCompetion(state, payload) {
        // console.log(payload)
        state.competion = payload
    },
    editSwim(state, payload) {
        // console.log(payload);
        state.editSwim = payload
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}