<template>
  <footer class="footer fix-footer">
    <div class="content has-text-centered">
      <!-- <p>
        <a href="https://swimtime.ru"><strong>Swimtime</strong></a> - история твоего плавания
      </p> -->
      <p>
        Пишите нам <a href="mailto:help@swimtime.ru"><b-icon icon="envelope"></b-icon></a>
      </p>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>
.fix-footer {
  margin-top: 60px;
}
</style>