import axios from "axios";
import store from '../store/index';
import router from '../router/index';

const jwtInterceptor = axios.create({});


jwtInterceptor.interceptors.request.use((config) => {
    const authData = store.getters["auth/getAuthData"];
    if (authData == null) {
        return config;
    }

    config.headers.common["Authorization"] = `Bearer ${authData.token}`;
    return config;
});

jwtInterceptor.interceptors.response.use(
    (response) => {
        return response;
    },
    async(error) => {
        if (error.response.status === 401) {
            const authData = store.getters["auth/getAuthData"];
            // const payload = {
            //   access_token: authData.token,
            //   refresh_token: authData.refreshToken,
            // };

            const refresh_token = authData.refresh

            await axios.get(
                "/auth/refresh", { headers: { Authorization: `Bearer ${refresh_token}` } }
            ).then((response) => {
                // await store.dispatch("auth/saveTokensToStorage", response.data);
                store.commit("auth/saveTokenData", response.data.data);
                store.commit("auth/setLoginStatu", 'success');

                error.config.headers[
                    "Authorization"
                ] = `Bearer ${response.data.data.token}`;
                return axios(error.config);
            }).catch((error) => {
                store.commit("auth/setLoginStatu", 'expired');
                store.dispatch("auth/logout");
                router.push('/login');
                return Promise.reject(error);
            });
        } else {
            store.commit("auth/setLoginStatu", 'failed');
            store.commit("auth/logout");
            return Promise.reject(error);
        }
    }
);

export default jwtInterceptor;