<template>
      <div class="container">
        <!-- <b>Hello, {{ getAuthData().user }}</b>
        <p>{{ getSwims() }}</p> -->
        <!-- <li v-for="swim in getSwims().results" :key="swim.id">
          {{ swim }}
        </li> -->
        <!-- <b-table :data="getSwims().results" :columns="columns"></b-table> -->

          <b-table
            :data="getSwims().results"
            :bordered="false"
            :striped="true"
            :narrowed="true"
            :hoverable="true"
            :loading="false"
            :focusable="false"
            :paginated="true"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="false"
            pagination-position="bottom"
            :pagination-rounded="false"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            :page-input-position="inputPosition"
            :show-detail-icon="true"
            :opened-detailed="opened"
            detailed
            detail-key="id"
            detail-transition="fade"
            :mobile-cards="true">

            <b-table-column sortable centered field="id" label="#" width="40" numeric v-slot="props">
                <template v-if="props.row.id == selected.id"><b-icon icon="edit"></b-icon> </template>
                <template v-else>{{ props.index + 1 }}</template>
            </b-table-column>

            <b-table-column centered field="event" label="Дисциплина" v-slot="props">
                {{ props.row.event_human.stroke_str }} / {{ props.row.event_human.distance }} / {{ props.row.event_human.pool }}
            </b-table-column>

            <b-table-column sortable centered field="time_str" label="Время" v-slot="props">
                {{ time_format(props.row.time_str) }}
            </b-table-column>

            <b-table-column sortable centered field="grade" label="Разряд" v-slot="props">
                {{ props.row.grade[1] }}
            </b-table-column>

            <b-table-column sortable centered field="points" label="Очки FINA" v-slot="props">
                {{ props.row.points }}
            </b-table-column>

            <b-table-column sortable centered field="date" label="Дата" v-slot="props" :custom-sort="sortByDate">
                <!-- <span class="tag is-success"> -->
                    {{ new Date(props.row.date).toLocaleDateString() }}
                <!-- </span> -->
            </b-table-column>

            <!-- <b-table-column centered label="Примечание" v-slot="props">
                <template v-if="props.row.competion">{{ props.row.competion.name }} - {{ props.row.competion.step }}
                </template>
                <p><i>{{ props.row.comment }}</i></p>
            </b-table-column> -->
            <!-- <b-table-column centered field="comment" label="Комментарий" v-slot="props">
                {{ props.row.comment }}
            </b-table-column> -->

                <!-- <b-modal
                    v-model="isEdit"
                    has-modal-card
                    trap-focus
                    aria-role="dialog"
                    aria-label="Example Modal"
                    aria-modal>
                    <template #default="props">
                        <AddSwimBase v-if="swim" v-bind:is-edit="isEdit" @close="props.close"></AddSwimBase>
                    </template>
                </b-modal> -->

            <template #empty>
                <div class="has-text-centered">Пока пусто</div>
                <!-- <b-skeleton :animated="true" size="is-large" count=10></b-skeleton> -->
            </template>

            <template #detail="props">
                <div class="columns">
                    <div class="column">
                <article class="media">
                    <!-- <figure class="media-left">
                        <p class="image is-64x64">
                            <img src="~@/assets/stroke/placeholder-128x128.png">
                        </p>
                    </figure> -->
                    <div class="media-content">
                        <div class="content has-text-left">
                            <p>
                                <template v-if="props.row.competion">
                                    <em>{{ props.row.competion.name }} - {{ props.row.competion.step }}</em><br>
                                </template>
                                <template v-if="props.row.comment"><em>{{ props.row.comment }}</em><br></template>
                                <template v-if="props.row.next_grade != 0">
                                    До <strong>{{ props.row.next_grade[1] }}</strong> разряда ({{ time_format(props.row.next_grade[2]) }}) осталось
                                    <strong>{{ time_format(props.row.next_grade[3]) }}</strong>
                                    <br>
                                </template>
                            </p>
                        </div>
                    </div>
                </article>
                </div>
                <div class="column is-2">
                    <div class="buttons is-right">
                        <!-- <b-button type="is-dark" icon-left="pen" v-on:click="edit_swim(props.row)"></b-button> -->
                        <router-link :to = "{ name:'editswim', params: {swim: props.row} }" class="button is-dark" icon-left="pen">
                            <b-icon icon="pen"></b-icon>
                        </router-link>
                        <b-button type="is-danger" icon-left="trash" v-on:click="del_swim(props.row)"></b-button>
                    </div>
                </div>
                </div>

            </template>

        </b-table>



      </div>
</template>

//  "comment": "text",
//  "date": "Mon, 15 Mar 2021 00:00:00 GMT",
//  "event": { "distance": 50, "id": 1, "pool": 25, "stroke": 1 },
//  "grade": 0,
//  "id": 1,
//  "is_relay": false,
//  "time_str": "00:01:29.290000",
//  "user": { "email": "aaa", "id": 1, "is_active": true, "sex": 1 }

<script>
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';

  // import AddSwimBase from '../components/AddSwimBase';

  export default {
    name: 'Swim',
    data() {
      return {
        columns: [
                    {
              field: 'id',
              label: '#',
          },
          {
              field: 'date',
              label: 'Дата',
          },
          {
              field: 'time_str',
              label: 'Время',
          },
        ],
        selected: {},
        opened: [0],
        messages: "",
        isEdit: false,
        swim: "",
        perPage: 10,
        currentPage: 1,
        // hasInput: false,
        // paginationOrder: '',
        // inputPosition: '',
      }
    },
    components: {
        // AddSwimBase,
    },
    methods:  {
      ...mapGetters('swim', ['getSwims']),
      ...mapActions('swim', ['getAllSwims']),
      ...mapActions('swim', ['delSwimPromise']),
      ...mapActions('swim', ['updateSelectedEvents']),
      ...mapActions('swim', ['editSwim']),
      ...mapGetters('auth', ['getAuthData']),
      time_format: function(tm) {
        return moment(tm, "hh:mm:ss.SSSSSS").format('m:ss.SS')
        // return moment(100).duration('mm:ss.SS')
      },
      sortByDate(a, b, isAsc) {
        if (isAsc) {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
        } else {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
        }
      },
      edit_swim(swim) {
          this.isEdit = true;
          this.swim = swim;
          this.$store.dispatch("swim/editSwim", swim)
          this.$store.commit("swim/updateSelectedEvents",
            { stroke: swim.event_human.stroke, distance: swim.event_human.distance, pool: swim.event_human.pool })
      },
      del_swim(swim) {
          let th = this;
            this.$buefy.dialog.confirm({
                message: 'Удалить этот заплыв?',
                onConfirm: () => {
                    // console.log(swim);

                    th.delSwimPromise(swim)
                        .then((res) => {
                            console.log(res)
                            th.$store.dispatch("swim/getAllSwims");
                            // this.$router.push('swim')
                            })
                        .catch((error) => {
                            // console.log(error)
                            th.messages = error.message
                            })
            }
            })
            },
            // columnTdAttrs(row, column) {
            //     if (row.id === 'Total') {
            //         if (column.label === 'ID') {
            //             return {
            //                 colspan: 4,
            //                 class: 'has-text-weight-bold',
            //                 style: {
            //                     'text-align': 'left !important'
            //                 }
            //             }
            //         } else if (column.label === 'Gender') {
            //             return {
            //                 class: 'has-text-weight-semibold'
            //             }
            //         } else {
            //             return {
            //                 style: {display: 'none'}
            //             }
            //         }
            //     }
            //     return null
            // }
    },
    computed: {},
    beforeCreate: function() {
      this.$store.dispatch("swim/getAllSwims");
    },
  }
</script>
