<template>
<div>
<section id="hero" class="hero is-medium is-white">
 
          
        <b-navbar
        :mobile-burger="true"
        :close-on-click="false"
        >
        <template #brand>
            <b-navbar-item centered="true" tag="router-link" :to="{ path: '/' }">
                <b>Swimtime</b>&nbsp;
                <img
                    src="~@/assets/logo.jpg" 
                    alt="Сохрани своё время!"
                >
            </b-navbar-item>
        </template>
        <template #start>
            <!-- <b-navbar-item tag="router-link" :to="{ path: '/calc' }">
                Калькулятор FINA
            </b-navbar-item> -->
            <b-navbar-item tag="div" class="is-hidden-mobile">
                <div class="buttons is-grouped">
                    <router-link :to = "{ name:'calculator' }" class="button is-info is-light">
                        Калькулятор FINA
                    </router-link>
                </div>
            </b-navbar-item>
        </template>

        <template #end>
            <b-navbar-item tag="div" class="is-hidden-mobile">
                <div class="buttons is-grouped">
                    <template v-if="getAuthData.token == ''">
                        <router-link :to = "{ name:'login' }" class="button is-light">
                            Вход
                        </router-link>
                        <router-link :to = "{ name:'signup' }" class="button is-info">
                            Регистрация
                        </router-link>
                    </template>

                    <template v-else>
                    <router-link :to = "{ name:'addswim', params: {swim: {}}}" class="button is-light">
                            <b-icon icon="plus" ></b-icon>
                    </router-link>
                    <router-link :to = "{ name:'swims' }" class="button is-light">
                            Мои заплывы
                    </router-link>
                    <FilterSwim v-if="$route.name == 'swims'" />
                    <a class="button is-light" v-on:click=logoutUser>
                        Выход
                    </a>
                    </template>
                </div>
            </b-navbar-item>
            <b-navbar-item tag="div" class="is-hidden-tablet">
                <!-- <div class="buttons is-grouped"> -->
                    <router-link :to = "{ name:'calculator' }" class="navbar-item">
                        Калькулятор FINA
                    </router-link>
                    <template v-if="getAuthData.token == ''">
                        <router-link :to = "{ name:'login' }" class="navbar-item">
                            Вход
                        </router-link>
                        <router-link :to = "{ name:'signup' }" class="navbar-item">
                            Регистрация
                        </router-link>
                    </template>

                    <template v-else>
                    <router-link :to = "{ name:'addswim', params: {swim: {}}}" class="navbar-item">
                            Добавить заплыв
                    </router-link>
                    <router-link :to = "{ name:'swims' }" class="navbar-item">
                            Мои заплывы
                    </router-link>
                    <FilterSwim v-if="$route.name == 'swims'" />
                    <a class="navbar-item" v-on:click=logoutUser>
                        Выход
                    </a>
                    </template>
                <!-- </div> -->
            </b-navbar-item>
        </template>
    </b-navbar>
      </section>
</div>  
</template>

<script>
import FilterSwim from '../components/FilterSwimButton';
// import { mapState, mapActions } from 'vuex';
import { mapActions, mapGetters } from 'vuex';

  export default {
      
    name: 'Header',
    components: {
        FilterSwim,
    },
    // methods:  mapActions(['logoutUser', 'validateUser']),
    methods: {
        ...mapActions('auth', ['logout']),
        logoutUser: function() {
            this.logout()
            this.$router.push('/');
        },
        },
    // computed: mapState(['accessToken']),
    computed: mapGetters('auth', ['getLoginStatus', 'getAuthData'])
    // created: function (){
    //     this.validateUser()
    // }
  }
</script>

<style scoped>
/* .navbar {
  background-color: #0a0a0a;
  color: white;
  height: 9%;
  display:flex;
  justify-content:center;
} */
</style>