<template>
  <div id="app">
        <router-view :key="$route.fullPath"></router-view>
        <Hero v-if="$route.name == 'home'"/>
        <Footer/>
  </div>
</template>

<script>
// import Login from './components/Login.vue'
// import {mapGetters, mapActions} from 'vuex'
import Hero from './components/Hero.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Hero,
    Footer,
    // Login,
  },
  beforeCreate: function() {
    const accessToken = JSON.parse(localStorage.getItem('token', ''))
    const refreshToken = JSON.parse(localStorage.getItem('refresh', ''))

    // console.log(this.$store)
    // console.log(accessToken + refreshToken)

    if (accessToken && refreshToken) {
      this.$store.dispatch("auth/initialUser", { token: accessToken, refresh: refreshToken });
      this.$store.dispatch("auth/validate")
    }
  },
  // computed: mapGetters(["allSwims", "swimsCount", "loggedIn"]),
  // methods: mapActions(["fetchSwims", "getUser"]),
  // async mounted() {
  //   // this.$store.dispatch("fetchSwims")
  //   // this.fetchSwims(4);
  //   this.getUser();
  //   console.log(this.store)
  // },
}
</script>

<style>
#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  /* font-family: Trendrough, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  /* background-image: url('~@/assets/kindel.jpg'); */
  /* + */
  /* background-image: url('~@/assets/pexels-emily-rose-2062708.jpg'); */
  /* + */
  /* background-image: url('~@/assets/pexels-jim-de-ramos-1263349.jpg'); */
  /* background-image: url('~@/assets/pexels-kindel-media-7294677.jpg'); */
  /* background-image: url('~@/assets/pexels-kindel-media-8688138.jpg'); */
  /* background-image: url('~@/assets/pexels-kindel-media-8688144.jpg'); */
  /* + */
  /* background-image: url('~@/assets/pexels-kindel-media-8688149.jpg'); */
  /* background-image: url('~@/assets/pexels-kindel-media-8688613.jpg'); */
  /* ++ */
  /* background-image: url('~@/assets/pexels-kindel-media-8688617.jpg'); */
  /* + */
  /* background-image: url('~@/assets/pexels-kindel-media-9030294.jpg'); */
  /* background-image: url('~@/assets/pexels-kindel-media-9030295.jpg'); */
  /* background-image: url('~@/assets/pexels-kindel-media-9030296.jpg'); */
  /* background-image: url('~@/assets/pexels-kindel-media-9044051.jpg'); */
  /* + */
  /* background-image: url('~@/assets/pexels-pixabay-261185.jpg'); */
  /* + */
  /* background-image: url('~@/assets/pexels-tima-miroshnichenko-6012009.jpg'); */
  /* background-image: url('~@/assets/pexels-tirachard-kumtanom-347143.jpg'); */
  /* background: aqua; */
  background-size: cover; 
  min-height: 100vh; 
}

/* .navbar-item {
  background: url(https://preview.ibb.co/bVUFKU/logo_transparent.png) no-repeat center center;
  background-size: cover;
  width: 152px
} */
</style>
