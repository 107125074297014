<template>
  <div>
      <Header/>
      <SignUpBase/>
  </div>
</template>


<script>
    import Header from '../components/Header.vue';
    import SignUpBase from '../components/SignUpBase.vue';

    export default {
        name: 'SignUp',
        components: {
            Header,
            SignUpBase,
        }
    }
</script>

<style scoped>
</style>