<template>
    <div>
        <Header/>
        <div class="box">
            <div class="columns">
                <div class="column"><SelectStroke /><SelectStrokeMobile /></div>
                <div class="column">
                    <TimeInput />
                    <b-field label="Пол" :message="messages.sex" :type="inputColor(messages.sex)">
                        <b-radio-button  expanded v-model="sex"
                            name="sex"
                            type="is-info"
                            native-value="1">
                            <b-icon icon="male" size="is-medium"></b-icon>
                        </b-radio-button>
                        <b-radio-button expanded v-model="sex"
                            name="sex"
                            type="is-info"
                            native-value="2">
                            <b-icon icon="female" size="is-medium"></b-icon>
                        </b-radio-button>
                    </b-field>
                    <b-field label="." custom-class="has-text-white">
                        <b-button expanded icon-right="redo-alt" type="is-danger is-light" @click="reset">Очистить</b-button>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Очки FINA">
                        <b-input icon="star-half-alt" disabled v-model="pointsExt"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Разряд">
                        <b-input icon="medal" disabled v-model="gradeExt"> </b-input>
                    </b-field>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Header from '../components/Header';
    import SelectStroke from '../components/SelectStrokeCheckbox';
    import SelectStrokeMobile from '../components/SelectStrokeMobile';
    // import SelectStroke from '../components/SelectStrokeRadio';
    import TimeInput from '../components/TimeInput';

    import moment from 'moment';
    // import _ from 'lodash';

    export default {
        name: 'Calculator',
        metaInfo: {
            title: 'Калькулятор очков FINA',
            meta: [
                { vmid: 'description', property: 'description', content: 'Калькулятор очков FINA 2021 и разрядов ВФП' },
                { vmid: 'og:title', property: 'og:title', content: 'Калькулятор очков FINA 2021 и разрядов ВФП' },
                { vmid: 'og:description', property: 'og:description', content: 'Калькулятор очков FINA 2021 и разрядов ВФП' },
            ],
        },
        data: function () {
            return {
                messages: "",
                sex: "",
            };
        },
        components: {
            Header,
            SelectStroke,
            SelectStrokeMobile,
            TimeInput,
        },
        created: function() {
            // this.debouncedGetGrade = _.debounce(this.getGrade, 1000)
        },
        methods: {
            reset: function() {
                console.log("RESET")
                this.sex = "";
                this.$store.commit("swim/updateTime", "");
                this.$store.commit("swim/updatePoints", "");
                this.$store.commit("swim/updateGrade", "");
                this.$store.commit("swim/updateSelectedEvents", { stroke: "", distance:"", pool: "" })
                this.$store.dispatch("swim/filterEvents", this.$store.state.swim.selectedEvents);
            },
            getGrade: function() {
                const event = this.$store.state.swim.enabledEvents.event
                let payload = {
                    event: event.length == 1 ? event[0] : "",
                    swimtime: "00:" + this.$store.state.swim.time,
                    swimdate: moment(this.date).format("YYYY-MM-DD"),
                    sex: this.sex
                }

                if (payload.event > 0 && payload.swimtime.length >= 8 && payload.swimdate && payload.sex) {
                    // console.log(payload)
                    this.$store.dispatch("swim/filterGrade", payload);
                    this.$store.dispatch("swim/filterPoints", payload);
                }
                else {
                    // console.log(payload.swimtime.length)
                    // console.log(payload)
                }
            },
            inputColor: function (m) {
                if (m) {return 'is-danger'}
            },
        },
        watch: {
            sex: function() {
                console.log('WATCH')
                this.getGrade();
            },
            '$store.state.swim.enabledEvents': function() {
                this.getGrade();
            },
            '$store.state.swim.time': function() {
                this.getGrade();
            },
        },
        computed: {
            event: function() {
                let event = this.$store.state.swim.enabledEvents.event;
                return event.length == 1 ? event[0] : "";
            },
            time: function() {
                return this.$store.state.swim.time;
            },
            gradeExt: {
                get() {
                    const grade = this.$store.state.swim.grade
                    if (grade.label) {
                        return grade.label + " / " + moment(grade.time_str, "hh:mm:ss.SSSSSS").format('mm:ss.SS')
                    }
                    else {
                        return ""
                    }
                },
                set() {
                    this.xdebouncedGetGrade()
                }
            },
            pointsExt: {
                get() {
                    return this.$store.state.swim.points
                },
                set() {
                    this.xdebouncedGetPoints()
                }
            },
        },
    }
</script>

<style>
input[type="text"]:disabled {
  color: #000000;
}
</style>
