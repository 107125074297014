import Vue from 'vue';
import Router from 'vue-router';
import Login from '../pages/Login.vue';
import SignUp from '../pages/SignUp.vue';
// import Logout from '../components/Logout.vue';
import Home from '../pages/Home.vue';
import Swims from '../pages/Swims.vue';
import AddSwim from '../pages/AddSwim.vue';
import Calculator from '../pages/Calculator.vue';
import store from '../store'
import axios from 'axios'


Vue.use(Router);

export const router = new Router({
    mode: 'history',
    routes: [{
            path: '/',
            name: 'home',
            component: Home,
            meta: { requiredAuth: false }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: { requiredAuth: false }
        },
        {
            path: '/signup',
            name: 'signup',
            component: SignUp,
            meta: { requiredAuth: false }
        },
        {
            path: '/swims',
            name: 'swims',
            component: Swims,
            meta: { requiredAuth: true }
        },
        {
            path: '/add',
            name: 'addswim',
            component: AddSwim,
            props: true,
            meta: { requiredAuth: true }
        },
        {
            path: '/edit',
            name: 'editswim',
            component: AddSwim,
            props: true,
            meta: { requiredAuth: true }
        },
        {
            path: '/calc',
            name: 'calculator',
            component: Calculator,
            meta: { requiredAuth: false }
        },
    ]
});


router.beforeEach(async(to, from, next) => {
    // console.log(store.getters["auth/getAuthData"].token);
    if (!store.getters["auth/getAuthData"].token) {
        const token = JSON.parse(localStorage.getItem('token', ''));
        const refresh = JSON.parse(localStorage.getItem('refresh', ''));
        if (token) {
            const data = {
                token: token,
                refresh: refresh,
            };
            store.commit("auth/saveTokenData", data);
        }
    }
    let auth = store.getters["auth/isTokenActive"];

    if (!auth) {
        const authData = store.getters["auth/getAuthData"];
        if (authData.refresh) {
            await axios.get(
                "/auth/refresh", { headers: { Authorization: `Bearer ${authData.refresh}` } }
            ).then((response) => {
                store.commit("auth/saveTokenData", response.data.data);
                auth = true
                console.log("Auth: " + auth)
            }).catch((err) => {
                console.log(err)
                store.commit("auth/setLoginStatu", 'expired');
                store.dispatch("auth/logout");
                auth = false
            })
        }
    }

    if (to.fullPath == "/") {
        console.log("next1")
        return next();
    } else if (auth && !to.meta.requiredAuth) {
        console.log("next2")
        return next();
        // return next()
    } else if (!auth && to.meta.requiredAuth) {
        console.log("next3")
        return next('login');
    }

    return next();
});


// router.beforeEach((to, from, next) => {
//     if (router.app.$store.isAuthenticated.user.user) {
//         next();
//     } else {
//         next('/login');
//     }
// })

export default router;