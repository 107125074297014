import Vue from 'vue';
import Vuex from "vuex";
import authModule from './modules/auth';
import swimModule from './modules/swim';

Vue.use(Vuex);

// const store = createStore({
const store = new Vuex.Store({
    modules: {
        auth: authModule,
        swim: swimModule
    }
});

export default store;