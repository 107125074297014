<template>
  <div>
      <Header/>
      <AddSwimBase/>
  </div>
</template>


<script>
    import Header from '../components/Header';
    import AddSwimBase from '../components/AddSwimBase';

    export default {
        name: 'AddSwim',
        components: {
            Header,
            AddSwimBase,
        }
    }
</script>

<style scoped>
</style>